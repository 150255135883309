import * as React from "react";
import Layout from "../../components/Layout";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import Partnership_Logo_svg from "../../images/10. Partnerships/Partnership_Logo.svg";

import Partnership_Lockup_Config_V_svg from "../../images/10. Partnerships/Partnership_Lockup_Config_V.svg";
import Partnership_Lockup_Config_H_Stacked_svg from "../../images/10. Partnerships/Partnership_Lockup_Config_H_Stacked.svg";
import Partnership_Lockup_Config_H_svg from "../../images/10. Partnerships/Partnership_Lockup_Config_H.svg";

import Partnership_Lockup_Divid_Line_Black_svg from "../../images/10. Partnerships/Partnership_Lockup_Divid_Line_Black.svg";
import Partnership_Lockup_Divid_Line_Red_svg from "../../images/10. Partnerships/Partnership_Lockup_Divid_Line_Red.svg";
import Partnership_Lockup_Divid_Line_White_svg from "../../images/10. Partnerships/Partnership_Lockup_Divid_Line_White.svg";

import Toyota_Main_Logo_H_svg from "../../images/10. Partnerships/Toyota_Main_Logo_H.svg";
import Toyota_Main_Logo_V_svg from "../../images/10. Partnerships/Toyota_Main_Logo_V.svg";

import Partnership_Lockup_V_svg from "../../images/10. Partnerships/Partnership_Lockup_V.svg";
import Partnership_Lockup_H_svg from "../../images/10. Partnerships/Partnership_Lockup_H.svg";
import Partnership_Lockup_V_Stacked_svg from "../../images/10. Partnerships/Partnership_Lockup_V_Stacked.svg";
import Partnership_Lockup_H_Stacked_svg from "../../images/10. Partnerships/Partnership_Lockup_H_Stacked.svg";

import Carousel from "../../ui-blocks/Carousel";
import ImageScroller from "../../ui-blocks/ImageScroller";

import Partnership_Lockup_Example_1_jpg from "../../images/10. Partnerships/Partnership_Lockup_Example_1.jpg";
import Partnership_Lockup_Example_2_jpg from "../../images/10. Partnerships/Partnership_Lockup_Example_2.jpg";
import Partnership_Lockup_Example_3_jpg from "../../images/10. Partnerships/Partnership_Lockup_Example_3.jpg";

import Tabs from "../../ui-blocks/Tabs";

const Partnerships = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "rgb(154, 159, 163)", height: "650px" }}
      >
        <div
          className="content-flex"
          style={{ color: "#fff", top: "", textAlign: "left" }}
        >
          <h1
            className="copy"
            style={{ color: "#fff", top: "", textAlign: "left" }}
          >
            There are various ways to create co-branding partnerships and
            sponsorships lockups.
            <br />
            <br />
            To ensure the Toyota brand has visual impact and consistency, please
            refer to the following guidelines.
          </h1>
        </div>
      </div>

      <section className="component Section" id="logo-selection">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Logo Selection</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              Always aim to use the full colour Toyota logo. This will help
              provide maximum visual impact when creating partnerships with
              other co-brands. When necessary single-colour logo options are
              available.
            </div>
            <div className="copy spaced-p">
              In special cases, a vehicle logo may be used.
            </div>

            <div className="copy spaced-p">
              Never use the <em>Let's Go Places</em> logo in co-branding
              lockups.
            </div>
          </div>
        </div>

        {/* TODO: tabbed logo demo  */}

        <Tabs
          section="logo-selection"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Main_Logo_H_svg,
                  style: { height: "300px" },
                },
              },
              label: "Horizontal",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Toyota_Main_Logo_V_svg,
                  style: { height: "400px" },
                },
              },
              label: "Vertical",
            },
          ]}
        />
      </section>

      <section className="component Section" id="title-sponsorships">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Title Sponsorships</h2>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              For title sponsorships, the Toyota logo may be closely aligned or
              integrated with the logo or title of the event being sponsored.
            </div>

            <div className="copy spaced-p">
              The full colour horizontal Toyota logo is preferred.
            </div>

            <div className="copy spaced-p">
              Minimum clear space of 1X around the Toyota logo is needed to
              ensure visual impact.
            </div>

            <div className="copy spaced-p">
              The X unit of measurement is derived from the 3x3 grid of the
              staging platform.
            </div>
          </div>
        </div>

        {/* single image */}
        <div
          className="image"
          style={{ background: "white", height: "482px" }}
          data-compid="used-as-headline-1"
        >
          <div className="full-width">
            <div className="image" style={{ display: "flex", height: "500px" }}>
              <img
                alt=""
                src={Partnership_Logo_svg}
                style={{ height: "200px", margin: "auto" }}
              />
            </div>
          </div>
        </div>
        {/* end single image */}
      </section>

      <section className="component Section" id="co-branding-lockups">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Co-branding lockups</h2>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              The full coour Toyota logo (horizontal or vertical) is preferred.
              Select the version that best matches the shape of the co-branding
              logo and the surrounding layout.
            </div>

            <div className="copy spaced-p">
              The Toyota logo should carry the same visual weight as the
              co-branding logo, unless otherwise specified.
            </div>

            <div className="copy spaced-p">
              The Toyota logo should be first in the lockup unless otherwise
              specified.
            </div>
          </div>
        </div>

        <Tabs
          section="configurations"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Partnership_Lockup_V_svg,
                  style: { height: "200px" },
                },
              },
              label: "Side-By-Side 1",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Partnership_Lockup_H_svg,
                  style: { height: "400px" },
                },
              },
              label: "Side-By-Side 2",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Partnership_Lockup_V_Stacked_svg,
                  style: { height: "400px" },
                },
              },
              label: "Stacked 1",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Partnership_Lockup_H_Stacked_svg,
                  style: { height: "400px" },
                },
              },
              label: "Stacked 2",
            },
          ]}
        />
      </section>

      <section className="component Section" id="configurations">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Configurations</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              <h3>Clear space</h3>
              When combining the Toyota logo with another logo always use a
              divider line. The space between the logos and divider line should
              be 1X.
            </div>

            <div className="copy spaced-p">
              It's important to use the 1X minimum clear space requirement
              around the entire lockup.
            </div>

            <div className="copy spaced-p">
              The X unit of measurement is derived from the 3x3 grid of the
              staging platform.
            </div>
          </div>
        </div>

        <div
          className="fiftyfifty"
          style={{ background: "white", padding: "60px" }}
        >
          <div className="full-width">
            <div
              className="col"
              style={{ background: "transparent", padding: "0px" }}
            >
              <div className="block">
                <span>
                  <div className="image" style={{ height: "400px" }}>
                    <picture>
                      <img
                        alt=""
                        src={Partnership_Lockup_Config_V_svg}
                        alt="Partnership Lockup Config Vertical"
                      />
                    </picture>
                  </div>
                  <div className="caption-container bottom-center">
                    {/* <div className="caption">Don’t use the graphic signature with body text instead of typesetting <em>Let's Go Places</em> in Semibold italic.</div> */}
                  </div>
                </span>
              </div>
            </div>
            <div
              className="col"
              style={{ background: "transparent", padding: "0px" }}
            >
              <div className="block">
                <span>
                  <div className="image" style={{ height: "400px" }}>
                    <picture>
                      <img
                        alt=""
                        src={Partnership_Lockup_Config_H_Stacked_svg}
                        alt="Partnership Lockup Config H Stacked"
                      />
                    </picture>
                  </div>
                  <div className="caption-container bottom-center">
                    {/* <div className="caption">Don’t use the <em>Let's Go Places</em> graphic signature in combination with the <em>Let's Go Places</em> headline or the consumer brand or vehicle logo.</div> */}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="blog-content darker-background"
          style={{ paddingTop: "80px", paddingBottom: "80px" }}
        >
          <div className="container onecolumn">
            <div className="copy">
              <h3>Divider Line</h3>
              The divider line should extend to the full height or width of the
              Toyota logo including the wordmark. The thickness of the divider
              line should never be less than half the width of the downstroke of
              the T in the Toyota wordmark.
            </div>
          </div>
        </div>

        <div className="image" style={{ background: "white", padding: "60px" }}>
          <picture>
            <img
              alt=""
              src={Partnership_Lockup_Config_H_svg}
              alt="Partnership Logo"
              style={{ margin: "auto", display: "flex", width: "80%" }}
            />
          </picture>
        </div>

        <div
          className="blog-content darker-background"
          style={{ paddingTop: "80px", paddingBottom: "80px" }}
        >
          <div className="container onecolumn">
            <div className="copy">
              <h3>Divider Colour</h3>
              The divider line should always be the same colour as the Toyota
              wordmark, as shown.
            </div>
          </div>
        </div>

        <Tabs
          section="configurations"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "image",
                image: {
                  svg: Partnership_Lockup_Divid_Line_Black_svg,
                  style: { height: "400px" },
                },
              },
              label: "Full Colour",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Partnership_Lockup_Divid_Line_Red_svg,
                  style: { height: "400px" },
                },
              },
              label: "Single Colour",
            },
            {
              content: {
                style: "image",
                image: {
                  svg: Partnership_Lockup_Divid_Line_White_svg,
                  style: { height: "400px" },
                },
              },
              label: "Full Colour Reversed",
            },
          ]}
        />
      </section>

      <section className="component Section" id="layout">
        <div className="section-header lighter-background">
          <div className="container">
            <h2 className="ui header">Layout examples</h2>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: { svg: Partnership_Lockup_Example_1_jpg },
              caption: "first",
            },
            {
              image: { svg: Partnership_Lockup_Example_2_jpg },
              caption: "secontd",
            },
            {
              image: { svg: Partnership_Lockup_Example_3_jpg },
              caption: "third",
            },
          ]}
        ></ImageScroller>
        {/* 
        <Carousel variant='fixed-width' 
            slides={[
                {image: {svg: Partnership_Lockup_Example_1_jpg}, caption: 'first'}, 
                {image: {svg: Partnership_Lockup_Example_2_jpg}, caption: 'secontd'}, 
                {image: {svg: Partnership_Lockup_Example_3_jpg}, caption: 'third'}
        ]}>

        </Carousel> */}
      </section>

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Brand Campaigns"
        previousUrl="/brandguidelines/brand-campaigns"
      />
    </Layout>
  );
};

export default Partnerships;
